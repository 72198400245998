import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import AGB from "./components/AGB/agb";
import Course from "./components/Course/Course";
import Footer from "./components/Footer";
import Impressum from "./components/Impressum/Impressum";
import Datenschutz from "./components/Datenschutz/Datenschutz";
import Contact from "./components/Contact/Contact";
import Signup from "./components/Newsletter/NewsletterPage";
import Newsletter from "./components/Newsletter/NewsletterMainPage";
import BlogMain from "./components/Blog/BlogMain";
import BlogYoga from "./components/Blog/BlogYoga";
import BlogMindfulness from "./components/Blog/BlogMindfulness";
import BlogYoga1 from "./components/Blog/BlogYoga1";
import BlogYoga2 from "./components/Blog/BlogYoga2";
import BlogYoga3 from "./components/Blog/BlogYoga3";
import BlogYoga4 from "./components/Blog/BlogYoga4";
import BlogYoga5 from "./components/Blog/BlogYoga5";
import BlogYoga6 from "./components/Blog/BlogYoga6";
import BlogYoga7 from "./components/Blog/BlogYoga7";
import BlogYoga8 from "./components/Blog/BlogYoga8";
import BlogYoga9 from "./components/Blog/BlogYoga9";
import BlogYoga10 from "./components/Blog/BlogYoga10";
import BlogYoga11 from "./components/Blog/BlogYoga11";
import BlogYoga12 from "./components/Blog/BlogYoga12";
import BlogYoga13 from "./components/Blog/BlogYoga13";
import BlogYoga14 from "./components/Blog/BlogYoga14";
import PlusSize from "./components/PlusSize/PlusSizeYoga";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Widerrufsbelehrung from "./components/Widerruf/Widerrufsbelehrung";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/course" element={<Course />} />
          <Route path="/PlusSizeYoga" element={<PlusSize />} />
          <Route path="/about" element={<About />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/datenschutz" element={<Datenschutz />} />
          <Route path="/agb" element={<AGB />} />
          <Route path="/widerrufsbelehrung" element={<Widerrufsbelehrung />} />
          <Route path="/SuccessfulSignup" element={<Signup />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<BlogMain />} />
          <Route path="/blogYoga" element={<BlogYoga />} />
          <Route path="/VierJahreYoga" element={<BlogYoga1 />} />
          <Route path="/AchtsamkeitImAlltag" element={<BlogYoga2 />} />
          <Route path="/PerformanceImYoga" element={<BlogYoga3 />} />
          <Route path="/YogaGegenRueckenschmerzen" element={<BlogYoga4 />} />
          <Route path="/YogaGegenPeriodenschmerzen" element={<BlogYoga5 />} />
          <Route path="/Resilienz" element={<BlogYoga6 />} />
          <Route path="/PlusSizeYogaBlog" element={<BlogYoga7 />} />
          <Route path="/LiebeFuerDenRuecken" element={<BlogYoga8 />} />
          <Route path="/ErkenntnisseUeberMichUndDasLeben1" element={<BlogYoga9 />} />
          <Route path="/GruendeNichtMitYogaZuBeginnen" element={<BlogYoga10 />} />
          <Route path="/DasWurzelchakra" element={<BlogYoga11 />} />
          <Route path="/DasSakralchakra" element={<BlogYoga12 />} />
          <Route path="/DasNabelchakra" element={<BlogYoga13 />} />
          <Route path="/DasHerzchakra" element={<BlogYoga14 />} />
          <Route path="/blogMindfulness" element={<BlogMindfulness />} />
          <Route path="/Newsletter" element={<Newsletter />} />
          <Route path="*" element={<Navigate to="/"/>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
