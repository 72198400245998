import React from "react";
import { Container, Row, Col, Card, Button, CardGroup } from "react-bootstrap";
import Particle from "../Particle";
import blog1 from "../../Assets/Blog1_Foto1.webp";
import blog2 from "../../Assets/Blog3_Foto3.webp";
import blog3 from "../../Assets/Blog4_Foto6.webp";
import blog4 from "../../Assets/Blog5_Foto5.webp";
import blog7 from "../../Assets/Blog7_Foto1.webp";
import blog8 from "../../Assets/Blog8_Foto3.webp";
import blog10 from "../../Assets/Blog10_Foto1.webp";
import blog11 from "../../Assets/Blog11_Foto3.webp";
import blog12 from "../../Assets/Blog12_Foto5.webp";
import blog13 from "../../Assets/Blog13_Foto5.webp";
import blog14 from "../../Assets/Blog14_Foto1.webp";


import { useNavigate } from 'react-router-dom';

function BlogYoga() {

    const navigate = useNavigate();

    const routeChange = () =>{ 
      navigate("/VierJahreYoga");
    }

    const routeChange2 = () =>{ 
      navigate("/PerformanceImYoga");
    }

    const routeChange3 = () =>{ 
      navigate("/YogaGegenRueckenschmerzen");
    }

    const routeChange4 = () =>{ 
      navigate("/YogaGegenPeriodenschmerzen");
    }

    const routeChange5 = () =>{ 
      navigate("/PlusSizeYogaBlog");
    }

    const routeChange6 = () =>{ 
      navigate("/LiebeFuerDenRuecken");
    }

    const routeChange7 = () =>{ 
      navigate("/GruendeNichtMitYogaZuBeginnen");
    }

    const routeChange8 = () =>{ 
      navigate("/DasWurzelchakra");
    }

    const routeChange9 = () =>{ 
      navigate("/DasSakralchakra");
    }

    const routeChange10 = () =>{ 
      navigate("/DasNabelchakra");
    }

    const routeChange11 = () =>{ 
      navigate("/DasHerzchakra");
    }

  return (
    <Container fluid="true" className="blog-section" id="Blog">
      <Particle />
      <Container>

      <CardGroup>
        <Row style={{ justifyContent: "center", padding: "10px" }}>

        <Col md={4}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "0px",
            }}>
        
        <Card className="blog-card" style={{ backgroundColor: 'transparent'}}>
          <Card.Img variant="top" src={blog14} />
          <Card.Body>
            <Card.Title><h1 className="blog-card-heading">Das Herzchakra – Mein Herz ist erfüllt von Dankbarkeit</h1></Card.Title>
            <Card.Text>
            </Card.Text>
          </Card.Body>
            <Button className="blog-button" onClick={routeChange11}>Zum Blogbeitrag</Button>
            <Card.Footer>12. September 2024</Card.Footer>
        </Card>
        </Col>

        <Col md={4}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "0px",
            }}>
        
        <Card className="blog-card" style={{ backgroundColor: 'transparent'}}>
          <Card.Img variant="top" src={blog13} />
          <Card.Body>
            <Card.Title><h1 className="blog-card-heading">Das Nabelchakra – Ich bin mutig</h1></Card.Title>
            <Card.Text>
            </Card.Text>
          </Card.Body>
            <Button className="blog-button" onClick={routeChange10}>Zum Blogbeitrag</Button>
            <Card.Footer>30. August 2024</Card.Footer>
        </Card>
        </Col>

        <Col md={4}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "0px",
            }}>
        
        <Card className="blog-card" style={{ backgroundColor: 'transparent'}}>
          <Card.Img variant="top" src={blog12} />
          <Card.Body>
            <Card.Title><h1 className="blog-card-heading">Das Sakralchakra – Ich akzeptiere meine Emotionen</h1></Card.Title>
            <Card.Text>
            </Card.Text>
          </Card.Body>
            <Button className="blog-button" onClick={routeChange9}>Zum Blogbeitrag</Button>
            <Card.Footer>16. August 2024</Card.Footer>
        </Card>
        </Col>

        <Col md={4}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "0px",
            }}>
        
        <Card className="blog-card" style={{ backgroundColor: 'transparent'}}>
          <Card.Img variant="top" src={blog11} />
          <Card.Body>
            <Card.Title><h1 className="blog-card-heading">Das Wurzelchakra – Ich vertraue dem Leben</h1></Card.Title>
            <Card.Text>
            </Card.Text>
          </Card.Body>
            <Button className="blog-button" onClick={routeChange8}>Zum Blogbeitrag</Button>
            <Card.Footer>28. Juli 2024</Card.Footer>
        </Card>
        </Col>

        <Col md={4}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "0px",
            }}>
        
        <Card className="blog-card" style={{ backgroundColor: 'transparent'}}>
          <Card.Img variant="top" src={blog10} />
          <Card.Body>
            <Card.Title><h1 className="blog-card-heading">7 Gründe nicht mit Yoga zu beginnen – und warum diese eigentlich keine sind</h1></Card.Title>
            <Card.Text>
            </Card.Text>
          </Card.Body>
            <Button className="blog-button" onClick={routeChange7}>Zum Blogbeitrag</Button>
            <Card.Footer>31. Mai 2024</Card.Footer>
        </Card>
        </Col>
        
        <Col md={4}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "0px",
            }}>
        
        <Card className="blog-card" style={{ backgroundColor: 'transparent'}}>
          <Card.Img variant="top" src={blog8} />
          <Card.Body>
            <Card.Title><h1 className="blog-card-heading">Dem Rücken Liebe schenken: Die Bewegungsrichtungen der Wirbelsäule</h1></Card.Title>
            <Card.Text>
            </Card.Text>
          </Card.Body>
            <Button className="blog-button" onClick={routeChange6}>Zum Blogbeitrag</Button>
            <Card.Footer>17. März 2024</Card.Footer>
        </Card>
        </Col>

        <Col md={4}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "0px",
            }}>
        
        <Card className="blog-card" style={{ backgroundColor: 'transparent'}}>
          <Card.Img variant="top" src={blog7} />
          <Card.Body>
            <Card.Title><h1 className="blog-card-heading">Plus Size Yoga: Warum Körperideale im Yoga keinen Platz haben</h1></Card.Title>
            <Card.Text>
            </Card.Text>
          </Card.Body>
            <Button className="blog-button" onClick={routeChange5}>Zum Blogbeitrag</Button>
            <Card.Footer>3. März 2024</Card.Footer>
        </Card>
        </Col>

        <Col md={4}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "0px",
            }}>
        
        <Card className="blog-card" style={{ backgroundColor: 'transparent'}}>
          <Card.Img variant="top" src={blog4} />
          <Card.Body>
            <Card.Title><h1 className="blog-card-heading">Yoga gegen Periodenschmerzen: Yogapositionen mit welchen du Muskelverspannungen lösen kannst</h1></Card.Title>
            <Card.Text>
            </Card.Text>
          </Card.Body>
            <Button className="blog-button" onClick={routeChange4}>Zum Blogbeitrag</Button>
            <Card.Footer>3. Jänner 2024</Card.Footer>
        </Card>
        </Col>
        
        <Col md={4}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "0px",
            }}>
        
        <Card className="blog-card" style={{ backgroundColor: 'transparent'}}>
          <Card.Img variant="top" src={blog3} />
          <Card.Body>
            <Card.Title><h1 className="blog-card-heading">Yoga gegen Rückenschmerzen: Der Weg zu einem schmerzfreien Rücken</h1></Card.Title>
            <Card.Text>
            </Card.Text>
          </Card.Body>
            <Button className="blog-button" onClick={routeChange3}>Zum Blogbeitrag</Button>
            <Card.Footer>17. Oktober 2023</Card.Footer>
        </Card>
        </Col>


        <Col md={4}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "0px",
            }}>
        
        <Card className="blog-card" style={{ backgroundColor: 'transparent'}}>
          <Card.Img variant="top" src={blog2} />
          <Card.Body>
            <Card.Title><h1 className="blog-card-heading">Performance-Druck in der heutigen Yoga-Welt: Eine tiefe Reflexion</h1></Card.Title>
            <Card.Text>
            </Card.Text>
          </Card.Body>
            <Button className="blog-button" onClick={routeChange2}>Zum Blogbeitrag</Button>
            <Card.Footer>23. September 2023</Card.Footer>
        </Card>
        </Col>


        <Col md={4}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "0px",
            }}>
        <Card className="blog-card" style={{ backgroundColor: 'transparent'}}>
      <Card.Img variant="top" src={blog1} />
      <Card.Body>
        <Card.Title><h1 className="blog-card-heading">Vier Jahre Yoga: Eine Reise der Veränderung auf mentaler und körperlicher Ebene</h1></Card.Title>
        <Card.Text>
        </Card.Text>
        </Card.Body>
        <Button className="blog-button" onClick={routeChange}>Zum Blogbeitrag</Button>
        <Card.Footer>6. September 2023</Card.Footer>
        </Card>
        </Col>
        </Row>
        </CardGroup>
      </Container>
    </Container>
  );
}

export default BlogYoga;
